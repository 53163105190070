const combineValues = (...objects) => objects.reduce((acc, obj) => [...acc, ...Object.values(obj)], []);

// Prefixes

// Sequencer
const VPP_SEQUENCER_DATADOG_KEY_PREFIX = 'vpp_sequencer_fe';

// Add to cart
const ADD_TO_CART_DATADOG_KEY_PREFIX = 'vpp_add_to_cart';

// Questions AI
const QUESTIONS_AI_DATADOG_KEY_PREFIX = 'vpp_questions_ai_fe';

// Error boundary
const VPP_BOUNDARY_DATADOG_KEY_PREFIX = 'vpp_boundary_fe';

// Espacio para otros prefixes
// Espacio para otros prefixes

// Cases

// Sequencer cases
const VPP_SEQUENCER_DATADOG_KEY_CASES = {
  ACTION_CLICK: 'action_click',
  WEBVIEW_REDIRECT: 'webview_redirect',
  FLOW_END: 'flow_end',
  GET_RESPONSE: 'get_response',
  REDIRECT_TO_FALLBACK: 'redirect_to_fallback',
  SHOW_SNACKBAR: 'show_snackbar',
  VPP_LOAD: 'vpp_load',
  DISPLAY_INSTANTLY: 'display_instantly',
  PRELOAD_LOADING: 'preload_loading',
  PRELOAD_LOADED: 'preload_loaded',
  PRELOAD_SHOWN: 'preload_shown',
  PRELOAD_UPDATED: 'preload_updated',
};

// Add to cart cases
const ADD_TO_CART_DATADOG_KEY_CASES = {
  FLOW_TYPE: 'flow_type',
};

// Questions AI cases
const QUESTIONS_AI_DATADOG_KEY_CASES = {
  VPP_LOAD: 'vpp_load',
  QUESTION_LOAD: 'question_load',
  WEBVIEW_LOAD: 'webview_load',
  SUGGESTION_RESPONSE: 'suggestion_response',
  BAD_FEEDBACK: 'bad_feedback',
  ACTION_CLICK: 'action_click',
  FLOW_END: 'flow_end',
  SHOW_SNACKBAR: 'show_snackbar',
};

// Error boundary cases
const VPP_BOUNDARY_DATADOG_KEY_CASES = {
  VPP_BOUNDARY_TEST: 'vpp_boundary_test', // TODO: Remove this after testing
  ERROR_BOUNDARY_COMPONENT: 'error_boundary_component',
  ERROR_BOUNDARY_MIDDLEWARE: 'error_boundary_middleware',
  ERROR_BOUNDARY_GLOBAL: 'error_boundary_global',
  ERROR_BOUNDARY_TRY_CATCH: 'error_boundary_try_catch',
};

// Espacio para otros cases
// Espacio para otros cases

// All prefixes
const ALLOWED_KEY_PREFIXES = [
  VPP_SEQUENCER_DATADOG_KEY_PREFIX,
  ADD_TO_CART_DATADOG_KEY_PREFIX,
  QUESTIONS_AI_DATADOG_KEY_PREFIX,
  VPP_BOUNDARY_DATADOG_KEY_PREFIX,
];

// All cases
const ALLOWED_KEYS_CASES = combineValues(
  VPP_SEQUENCER_DATADOG_KEY_CASES,
  ADD_TO_CART_DATADOG_KEY_CASES,
  QUESTIONS_AI_DATADOG_KEY_CASES,
  VPP_BOUNDARY_DATADOG_KEY_CASES,
  // adicionar los keys que se necesiten de otras iniciativas
);

export {
  ALLOWED_KEY_PREFIXES,
  ALLOWED_KEYS_CASES,
  VPP_SEQUENCER_DATADOG_KEY_CASES,
  VPP_SEQUENCER_DATADOG_KEY_PREFIX,
  ADD_TO_CART_DATADOG_KEY_CASES,
  ADD_TO_CART_DATADOG_KEY_PREFIX,
  QUESTIONS_AI_DATADOG_KEY_PREFIX,
  QUESTIONS_AI_DATADOG_KEY_CASES,
  VPP_BOUNDARY_DATADOG_KEY_PREFIX,
  VPP_BOUNDARY_DATADOG_KEY_CASES,
};
