import DEFAULT_CONFIG from './default-config';
// import galleryConfig from '../features/gallery-configuration';

const boundaryConfigMap = {
  // Example:
  // some_feature_name: {
  //   ...DEFAULT_CONFIG,
  //   some_key: 'some_value',
  // },
  // TODO: Remove this comment when Gallery Custom Fallback Component is ready:
  // [`fixed.gallery`]: galleryConfig,
  // gallery: galleryConfig,
  default: DEFAULT_CONFIG,
};

export default boundaryConfigMap;
