// This is for hardcode critic components
// We do this while backend add this to the response

// VERTICALS:
const VPP_CORE_CRITIC_COMPONENTS = [];
const MISC_CRITIC_COMPONENTS = [];
// const RECOMMENDATIONS_CRITIC_COMPONENTS = []; // Example
// const APPAREL_CORE_CRITIC_COMPONENTS = []; // Example

const criticComponents = [
  ...VPP_CORE_CRITIC_COMPONENTS,
  ...MISC_CRITIC_COMPONENTS,
  //   ...RECOMMENDATIONS_CRITIC_COMPONENTS, // Example
  //   ...APPAREL_CORE_CRITIC_COMPONENTS, // Example
];

export default criticComponents;
