/**
 * Module dependencies
 */
import React from 'react';

import { StoreConsumer } from './context';
import ErrorBoundary from './error';
import getDisplayName from '../lib/get-display-name';
import getBoundaryConfig from '../error-boundary/get-config';
import get from 'lodash/get';

const noop = () => {};

/**
 * connect
 */
const connect = (mapStateToProps = noop, mapDispatchToProps = noop) => WrappedComponent => {
  const ConnectedComponent = ownProps => {
    const { featureName } = ownProps;
    return (
      <StoreConsumer>
        {({ history, state, dispatch }) => {
          const stateProps = {
            app: state?.app,
            deviceType: state?.deviceType,
          };
          const vppBoundaryConfig = getBoundaryConfig({
            componentId: featureName,
            backendConfig: state?.errorBoundaryGeneralConfiguration,
            extraConfig: stateProps,
            componentInfo: get(state, `components.${featureName}`, false),
          });

          return (
            <ErrorBoundary vppBoundaryConfig={vppBoundaryConfig}>
              <>
                <WrappedComponent
                  history={history}
                  vppBoundaryConfig={vppBoundaryConfig}
                  {...mapStateToProps(state, ownProps)}
                  {...mapDispatchToProps(dispatch, ownProps)}
                />
              </>
            </ErrorBoundary>
          );
        }}
      </StoreConsumer>
    );
  };
  ConnectedComponent.displayName = `Connect(${getDisplayName(WrappedComponent)})`;
  return ConnectedComponent;
};

/**
 * Expose connect
 */
export default connect;
