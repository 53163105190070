import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import { checkOnBulkSaleSetQuantity, hideHiQuantityError } from '../../../../actions/vip';

const FEATURE_NAME = 'bulk_sale_quantity';

const mapStateToProps = ({ components: { [FEATURE_NAME]: bulkSaleQuantity } }) => bulkSaleQuantity;

const mapDispatchToProps = dispatch => ({
  onSetQuantity: data => dispatch(checkOnBulkSaleSetQuantity(data)),
  onOpenModal: () => dispatch(hideHiQuantityError()),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const calculator = BulkSaleCalculator => enhance(BulkSaleCalculator);

export default calculator;
