import boundaryConfigMap from './config/config-map';
import criticComponents from './config/critic-components';
import DEFAULT_CONFIG from './config/default-config';
import fallbackComponentsMap from './components/fallback-components-map';

const getBoundaryConfig = ({ componentId, backendConfig = {}, extraConfig = {}, componentInfo }) => {
  try {
    // Get the component boundary config...
    const { mapComponentInfoToProps, ...componentConfig } = boundaryConfigMap[componentId] || boundaryConfigMap.default;
    const { isCritic: isCriticFromMap, hasFallback } = componentConfig;

    // Get the critic & fallback config...
    const frontendHardcodedCriticComponents = criticComponents || [];
    const backendCriticComponents = backendConfig?.criticComponents || [];
    const criticComponentsList = [...frontendHardcodedCriticComponents, ...backendCriticComponents];
    const isCritic = isCriticFromMap || criticComponentsList.includes(componentId);
    const shouldRenderFallback = isCritic && hasFallback && componentInfo && mapComponentInfoToProps;

    return {
      ...componentConfig,
      ...extraConfig,
      componentId,
      isCritic,
      fallbackProps: shouldRenderFallback && mapComponentInfoToProps(componentInfo),
      FallbackComponent: shouldRenderFallback && fallbackComponentsMap[componentId],
    };
  } catch (error) {
    // Return the default config if something goes wrong
    return {
      ...DEFAULT_CONFIG,
      ...extraConfig,
      componentId,
    };
  }
};

export default getBoundaryConfig;
