import React from 'react';
import { node, shape, string } from 'prop-types';
import { feStatsdHelper } from '../utils/frontendStatsHelper';

import {
  VPP_BOUNDARY_DATADOG_KEY_CASES,
  VPP_BOUNDARY_DATADOG_KEY_PREFIX,
} from '../services/frontend-statsd/config/allowed-keys';

// eslint-disable-next-line no-console
const logErrorToMyService = (error, info) => console.log(error, info);

// Datadog config - ERROR BOUNDARY
const statsdConfig = {
  keyPrefix: VPP_BOUNDARY_DATADOG_KEY_PREFIX,
};

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, info);

    const { vppBoundaryConfig } = this.props;

    feStatsdHelper({
      key: VPP_BOUNDARY_DATADOG_KEY_CASES.ERROR_BOUNDARY_COMPONENT,
      statsdConfig,
      additionalTags: {
        catch_context: 'client_side',
        referer_app: vppBoundaryConfig?.app,
        device_type: vppBoundaryConfig?.deviceType,
        component_id: vppBoundaryConfig?.componentId,
      },
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // intentionally do nothing for now
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
  vppBoundaryConfig: shape({
    componentId: string,
  }),
};

export default ErrorBoundary;
