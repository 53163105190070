import React from 'react';
import { node, shape, func, string } from 'prop-types';
import { Snackbar } from '@andes/snackbar';
import GlobalErrorBoundary from './global-error';
import thunk from './middleware/thunk';
import useEnhancedReducer from '../hooks/use-enhance-reducer';
import { validateSnackbarColor } from '../utils/validators';

const { Provider, Consumer: StoreConsumer } = React.createContext();

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line n/global-require
  const logger = require('./middleware/logger').default;
  middlewares.push(logger);
}

const StoreProvider = ({ children, defaultState, reducer, siteId }) => {
  const [state, dispatch] = useEnhancedReducer(reducer, defaultState, middlewares);
  const store = { dispatch, state };
  const { feedback } = state;

  return (
    <GlobalErrorBoundary
      siteId={siteId}
      deviceType={defaultState?.deviceType}
      app={defaultState?.app}
      dispatch={dispatch}
      error={state.error}
    >
      <Provider value={store}>{children}</Provider>
      {feedback && feedback.style && (
        <Snackbar
          key={feedback.key}
          show
          message={feedback.message}
          delay={feedback.duration}
          color={validateSnackbarColor(feedback.style.toLowerCase())}
        />
      )}
    </GlobalErrorBoundary>
  );
};

StoreProvider.propTypes = {
  children: node.isRequired,
  defaultState: shape(),
  reducer: func.isRequired,
  siteId: string,
  deviceType: string,
};

StoreProvider.defaultProps = {
  defaultState: {},
  siteId: null,
  deviceType: null,
};

export { StoreProvider, StoreConsumer };
