import React, { useState, useMemo, useRef, useContext, useEffect } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { Image } from 'nordic/image';
import { loadable } from 'nordic/lazy';
import useGallery from './hooks/use-gallery';
import GallerySlide from './components/desktop/gallery.slide';
import { trackEvent } from '../../lib/tracking';
import StaticPropsContext from '../context/static-props';

import { BLANK_IMAGE, DEFAULT_GALLERY_LIMIT, DEFAULT_GALLERY_SELECTED, DEFAULT_LIGHTBOX_SETTINGS } from './constants';

import { constants } from '../../utils/constants';
import { PROVIDER } from '../figure/constants';

const namespace = 'ui-pdp-gallery';
const visibleClass = `${namespace}__zoom-container--visible`;
const { DEVICE_TYPE, LAYOUT } = constants;

const ClipsWorkerComponent = loadable(() => import('./components/desktop/clips-worker-component-desktop'), {
  resolveComponent: components => components.ClipsWorkerComponent,
});

const Lightbox = loadable(() => import('../lightbox'));
Lightbox.preload();

/* istanbul ignore next */
const setZoomLeftPosition = ({ left, layout }) => {
  switch (layout) {
    case LAYOUT.UPP:
    case LAYOUT.PDP:
    case LAYOUT.SHOPS_PDP:
      return left + 460;
    case LAYOUT.VIP_CORE:
      return left + 784;
    case LAYOUT.SHOPS_VIP_CORE:
      return left + 800;
    case LAYOUT.VIP_REAL_ESTATE:
    case LAYOUT.VIP_SERVICES:
    case LAYOUT.QUOTATION:
    case LAYOUT.PORTAL_INMOBILIARIO:
      return left + 768;
    case LAYOUT.VIP_MOTORS:
    default:
      return left + 764;
  }
};

const GalleryDesktop = props => {
  const {
    accessibility_text,
    app,
    clipsConfig,
    defaultSelected,
    limit,
    figures,
    multimediaConfig,
    showSnackbar,
    title,
    track,
    overlayAlpha,
  } = props;

  const {
    position,
    snap,
    isCurrentClips,
    isFullscreen,
    isPreviousClips,
    setCurrentIndex,
    setIsFullscreen,
  } = useGallery({ initPosition: defaultSelected, items: figures, clipsConfig });

  const clipsRef = useRef(null);

  useEffect(() => {
    setCurrentIndex(defaultSelected);
  }, [defaultSelected, figures, setCurrentIndex]);

  const clipsWorkerComponentProps = {
    clipsConfig,
    isCurrentClips,
    isPreviousClips,
    isFullscreen,
    showSnackbar,
    snap,
    position,
    figures,
    ref: clipsRef,
  };

  const galleryRef = useRef();
  const zoomRef = useRef();

  const [modalVisibility, setModalVisibility] = useState(false);
  const [galleryOptions, setGalleryOptions] = useState({
    top: 0,
    left: 0,
  });
  const { deviceType, layout } = useContext(StaticPropsContext);

  /* istanbul ignore next */
  const zoomOptions = useMemo(
    () => ({
      namespace: `${namespace}__zoom`,
      onShow: () => {
        const zoom = zoomRef.current;
        const firstThumbnail = document
          .querySelector('.ui-pdp-gallery__thumbnail:first-of-type')
          .getBoundingClientRect();

        setGalleryOptions({
          top: firstThumbnail.top,
          left: setZoomLeftPosition({ left: firstThumbnail.left, layout }),
        });
        document.body.appendChild(zoom);

        if (zoomRef.current) {
          zoomRef.current.classList.add(visibleClass);
        }
      },
      onHide: () => {
        if (zoomRef.current) {
          zoomRef.current.classList.remove(visibleClass);
        }
      },
    }),
    [zoomRef, layout],
  );
  const nameItem = figures[0]?.alt || '';
  const attributeRole = accessibility_text ? 'group' : null;
  const attributeAriaLabel = accessibility_text ? `${accessibility_text}${nameItem && `, ${nameItem}`}` : null;

  const handlerBeforeOpen = () => {
    setIsFullscreen(true);
  };

  const handlerLightboxOnClose = () => {
    setIsFullscreen(false);
    setModalVisibility(false);
  };

  const handlerFullScreen = () => {
    setModalVisibility(true);
  };

  const clipHandler = () => {
    if (clipsRef.current) {
      clipsRef.current?.clipHandler();
    }
  };

  return (
    <div ref={galleryRef} className={namespace} role={attributeRole} aria-label={attributeAriaLabel}>
      <div
        ref={zoomRef}
        className={`${namespace}__zoom-container`}
        style={{ top: galleryOptions.top, left: galleryOptions.left }}
      />
      <div className={`${namespace}__column`}>
        <div className={`${namespace}__column__variation-picture`}>
          <Image
            id="variation-gallery"
            alt="variation-gallery"
            className={`${namespace}__column__variation-gallery`}
            src={BLANK_IMAGE}
            lazyload="off"
            decoding="async"
          />
        </div>
        {figures.map((figure, index) => {
          const { zoom, provider } = figure;
          const pictureZoomOptions = {
            ...zoomOptions,
            enableZoom: !!zoom && deviceType === DEVICE_TYPE.DESKTOP,
          };
          const isOutLimit = figures.length > limit && index + 1 === limit;
          const seeMoreAmount = isOutLimit ? figures.length - limit + 1 : 0;

          const keyGallery = `${namespace}-${index}`;
          return (
            <GallerySlide
              key={keyGallery}
              autoplay={clipsConfig?.autoplay}
              index={index}
              container={zoomRef}
              zoomOptions={pictureZoomOptions}
              selected={position.current === index}
              title={title}
              onMouseHover={() => setCurrentIndex(index)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setCurrentIndex(index);
                }
              }}
              onClick={() => {
                if (provider === PROVIDER.CLIP) {
                  clipHandler();
                } else {
                  setModalVisibility(true);
                  if (track) {
                    trackEvent(track);
                  }
                }
              }}
              onMute={(...params) => clipsRef.current && clipsRef.current?.onMute(...params)}
              onFullscreen={handlerFullScreen}
              showThumbnail={index < limit}
              seeMoreAmount={seeMoreAmount}
              provider={provider}
              figure={figure}
              overlayAlpha={overlayAlpha}
              app={app}
            />
          );
        })}
      </div>
      {modalVisibility && (
        <Lightbox
          index={position.current}
          setIndex={setCurrentIndex}
          pictures={figures}
          deviceType={DEVICE_TYPE.DESKTOP}
          onBeforeOpen={handlerBeforeOpen}
          onClose={handlerLightboxOnClose}
          onTouch={(...params) => clipsRef.current && clipsRef.current?.handleTouch(...params)}
          settings={DEFAULT_LIGHTBOX_SETTINGS.desktop(namespace)}
          videoConfig={multimediaConfig}
        />
      )}
      {clipsConfig?.hasClips && <ClipsWorkerComponent {...clipsWorkerComponentProps} />}
    </div>
  );
};

GalleryDesktop.propTypes = {
  track: shape({}),
  clipsConfig: shape({
    gallery_position: number,
    autoplay: bool,
    snackbars: arrayOf(
      shape({
        id: string.isRequired,
        message: string,
        delay: number,
      }),
    ),
  }),
  defaultSelected: number,
  hasClips: bool,
  limit: number,
  figures: arrayOf(
    shape({
      id: string.isRequired,
      alt: string,
      src: string,
      src2x: string,
    }),
  ).isRequired,
  title: string,
  multimediaConfig: shape({
    alt: string,
    title: string,
    allow: string,
    frameBorder: number,
    showSpinner: bool,
  }),
  accessibility_text: string,
  app: string,
  showSnackbar: func,
  overlayAlpha: string,
};

GalleryDesktop.defaultProps = {
  track: null,
  limit: DEFAULT_GALLERY_LIMIT,
  defaultSelected: DEFAULT_GALLERY_SELECTED,
  title: null,
  multimediaConfig: null,
  accessibility_text: '',
  app: null,
  hasClips: false,
  clipsConfig: {},
  showSnackbar: null,
  overlayAlpha: null,
};

export default GalleryDesktop;
