import React from 'react';
import { shape, string, number } from 'prop-types';
import classNames from 'classnames';
import IFrameReviews from '../iframe-reviews';
import usePostClose from '../reviews/hooks/use-post-close';
import { trackEvent } from '../../lib/tracking';
import { forEach } from '../../lib/dom-utils';
import HistoryService from '../../services/history';
import { getStarsToDraw } from './utils/stars-draw';
import { runSchedulerTask } from '../../utils/validators';

const namespace = 'ui-pdp-review';

const ReviewSummary = ({
  rating,
  formatted_rating_average,
  action,
  stars,
  rating_stars,
  subtitle,
  formatted_total,
  title,
  aria_label,
}) => {
  const ratings = getStarsToDraw(rating_stars, stars);
  const [isReviewsVisible, setReviewsVisible] = usePostClose(false);

  if (action) {
    return (
      <a
        href={action.target}
        className={classNames(`${namespace}__label`, { [`${namespace}__label--link`]: action })}
        onClick={e => {
          e.preventDefault();
          setReviewsVisible(true);
          trackEvent(action.track);
          // Se calculan en base al react-remove-scroll-bar y cumplen la función de suplantar el espacio
          // dejado por la scrollbar en los casos que se utilice mouse
          // checks para omitir el test del dom
          const main = document.querySelectorAll('body > header, body > main') || [];

          forEach(main, element => {
            element.classList.add('with-scroll-bars-hidden');
          });
        }}
        data-testid="review-summary"
      >
        <span aria-hidden="true" className={`${namespace}__rating`}>
          {/* TODO: lógica en front para hacer safe el deploy, quitar luego de aplicar esta lógica en back */}
          {formatted_rating_average?.text ?? rating.toFixed(1)}
        </span>
        <span aria-hidden="true" className={`${namespace}__ratings`}>
          {ratings}
        </span>
        <span className="andes-visually-hidden">{aria_label}</span>
        <span aria-hidden="true" className={`${namespace}__amount`}>
          {formatted_total?.text ?? subtitle}
        </span>
        <IFrameReviews
          visible={isReviewsVisible}
          action={action}
          title={title}
          hashId="reviews-summary"
          onClose={e => {
            if (e) {
              e.preventDefault();
            }

            setReviewsVisible(false);
            // Se calculan en base al react-remove-scroll-bar y cumplen la función de suplantar el espacio
            // dejado por la scrollbar en los casos que se utilice mouse
            const main = document.querySelectorAll('body > header, body > main') || [];

            forEach(main, element => {
              element.classList.remove('with-scroll-bars-hidden');
            });

            runSchedulerTask(() => {
              const historyService = HistoryService;
              historyService.back();
            }, 'background');
          }}
        />
      </a>
    );
  }
  return (
    <div className={`${namespace}__label`}>
      <span aria-hidden="true" className={`${namespace}__rating`}>
        {/* TODO: lógica en front para hacer safe el deploy, quitar luego de aplicar esta lógica en back */}
        {formatted_rating_average?.text ?? rating.toFixed(1)}
      </span>
      <span aria-hidden="true" className={`${namespace}__ratings`}>
        {ratings}
      </span>
      <span className="andes-visually-hidden">{aria_label}</span>
      <span aria-hidden="true" className={`${namespace}__amount`}>
        {formatted_total?.text ?? subtitle}
      </span>
    </div>
  );
};
ReviewSummary.propTypes = {
  rating: number.isRequired,
  formatted_rating_average: shape({
    text: string,
  }),
  action: shape({
    target: string,
    track: shape({}),
  }),
  stars: string.isRequired,
  rating_stars: shape({
    value: number,
  }),
  subtitle: string.isRequired,
  formatted_total: shape({
    text: string,
  }),
  title: string,
  aria_label: string.isRequired,
};

ReviewSummary.defaultProps = {
  action: null,
  title: null,
  formatted_rating_average: null,
  rating_stars: null,
  formatted_total: null,
};

export default ReviewSummary;
